.notificationContent {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.06);
  padding: clamp(16px, 3vw, 24px);
  width: 100%;
  margin: 0 auto;
  /* transition: all 0.2s ease-in-out; */
  box-sizing: border-box;
}

@media (max-width: 1024px) {
  .notificationContent {
    max-width: 90%;
  }
}

@media (max-width: 768px) {
  .notificationContent {
    max-width: 100%;
    margin: 0;
    border-radius: 8px;
  }
}

.notificationContent:hover {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.08);
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.headerMain {
  display: flex;
  align-items: center;
  gap: 16px;
}

.typeIcon {
  width: clamp(32px, 5vw, 40px);
  height: clamp(32px, 5vw, 40px);
  border-radius: 50%;
  padding: clamp(6px, 1vw, 8px);
  background: #f8f9fa;
  /* transition: transform 0.2s ease; */
  flex-shrink: 0;
}

.headerText {
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  min-width: 0;
}

.headerText h3 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.senderName {
  font-size: 16px;
  font-weight: 600;
  color: #6B6B6B;
  margin: 0;
  text-transform: capitalize;
}

.timeWrapper {
  display: flex;
  align-items: center;
  gap: 6px;
  color: #666;
  font-size: 13px;
}

.timestamp {
  color: #949494;
}

.actionLinks {
  display: flex;
  gap: 12px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background: #F2F2F2;
  color: #4F63BE;
  font-size: 0.875rem;
  cursor: pointer;
  /* transition: all 0.2s ease; */
  height: 2rem;
}

.actionButton:hover:not(:disabled) {
  background: #e2e8f0;
}

.actionButton span {
  white-space: nowrap;
  display: inline-block;
}

@media (max-width: 768px) {
  .actionButton {
    flex: 0 1 auto;
    justify-content: center;
    min-width: 120px;
  }
}

.readButton {
  color: #0066ff;
}

.readButton:hover {
  background: rgba(0, 102, 255, 0.08);
}

.unreadButton {
  color: #666;
}

.unreadButton:hover {
  background: rgba(0, 0, 0, 0.05);
}

.deleteButton {
  background: #ffffff;
  /* color: #ff4d4d; */
}

.deleteButton:hover {
  background: #ffffff !important;
  /* background: rgba(255, 77, 77, 0.08); */
}

.contentSection {
  padding: 0 8px;
}

.title {
  font-size: clamp(16px, 2.5vw, 20px);
  font-weight: 600;
  color: #6B6B6B;
  margin: 0 0 clamp(16px, 3vw, 24px) 0;
  line-height: 1.3;
  letter-spacing: -0.5px;
}

.body {
  color: #2c3e50;
  font-size: clamp(14px, 1.8vw, 16px);
  line-height: 1.6;
  letter-spacing: 0.2px;
  max-width: 100%;
  overflow-wrap: break-word;
  word-wrap: break-word;
}

@media (max-width: 768px) {
  .title {
    letter-spacing: -0.3px;
  }

  .body {
    letter-spacing: 0;
  }
}

.description {
  margin: 0 0 20px 0;
  font-size: 14px;
  color: #6B6B6B;
  line-height: 1.7;
  letter-spacing: 0.2px;
}

.additionalText {
  margin: 20px 0;
  color: #6B6B6B;
  font-size: 15px;
  line-height: 1.6;
}

.signature {
  /* margin-top: 32px; */
  padding-top: 20px;
  /* border-top: 1px dotted #edf2f7; */
}

.footerText {
  color: #6B6B6B;
  font-size: 14px;
  line-height: 1.7;
  margin: 0;
  /* font-style: italic; */
}

.signatureName {
  color: #6B6B6B;
  font-weight: 500;
  font-size: 14px;
}

.actions {
  margin-top: 24px;
  display: flex;
  gap: 12px;
}

.primaryButton {
  display: flex;
  align-items: center;
  gap: 8px;
  transform: none;
  min-width: 7.4375rem;
  height: 2rem;
  padding: 0.5rem 1rem;
  border: none;
  font-weight: 500;
  font-size: 0.875rem;
  /* transition: 0.2s; */
  cursor: pointer;
  color: white;
  background-color: #4f63be;
}

.primaryButton:hover {
  background: #0052cc;
}

/* Empty state styles */
.noSelection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 48px;
  margin: auto;
}

.emptyState {
  text-align: center;
  max-width: 320px;
}

.emptyIcon {
  width: 80px;
  height: 80px;
  margin-bottom: 24px;
  opacity: 0.8;
}

.emptyState h3 {
  font-size: 20px;
  font-weight: 600;
  color: #1a1a1a;
  margin: 0 0 8px 0;
}

.emptyState p {
  color: #666;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}