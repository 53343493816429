.notificationItem {
  display: flex;
  gap: 1rem;
  padding: .8rem;
  /* background: #F5F9FF; */
  cursor: pointer;
  transition: all 0.2s ease;
  border-bottom: 1px solid #F6F6F6;
}

.notificationItem:hover {
  background: #F8FAFC;
  border-color: #E2E8F0;
}

.selected {
  /* border-left: 3px solid #3B82F6; */
  /* background: #EBF5FF;
  border-radius: 0 8px 8px 0; */
  background: linear-gradient(90deg,
      rgba(255, 255, 255, 0.5) -15.76%,
      rgba(233, 239, 247, 0.5) 62.68%);
  padding-left: calc(1rem - 3px);
  border-bottom: 1px solid #F6F6F6;
}

/* .selected:hover {
  background: #E0F2FE;
  border-left-color: #2563EB;
} */

.checkboxWrapper {
  position: relative;
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;
  z-index: 1;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 18px;
  height: 18px;
  z-index: 2;
}

.checkmark {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  width: 16px;
  background-color: white;
  border: 1.5px solid #CBD5E1;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.checkbox:checked~.checkmark {
  background-color: #4F63BE;
  border-color: #4F63BE;
  color: white;
}

.checkbox:checked~.checkmark svg {
  opacity: 1;
  transform: scale(1);
}

.checkmark svg {
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.2s ease;
}

.notificationContent {
  flex: 1;
  min-width: 0;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
}

.title {
  margin: 0;
  font-size: 0.938rem;
  font-weight: 700;
  color: #6B6B6B;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.selected .title {
  color: #4F63BE;
}

.unread .title {
  font-weight: 700;
  color: #0F172A;
}

.timeStamp {
  display: flex;
  align-items: center;
  gap: 0.25rem;
  padding-top: .2rem;
  color: #6B6B6B;
  font-size: 0.75rem;
  white-space: nowrap;
}

.description {
  margin: 0;
  margin-top: 8px;
  font-size: 0.875rem;
  color: #6B6B6B;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  line-height: 1.5;
}

.unread .description {
  color: #334155;
}

.actions {
  display: flex;
  /* gap: 0.75rem; */
  margin-top: 0.5rem;
  justify-content: flex-end;
  align-items: center
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.375rem;
  padding: 0.6rem;
  border: none;
  border-radius: 6px;
  font-size: 0.813rem;
  cursor: pointer;
  transition: all 0.2s ease;
  background: transparent;
}

.readButton {
  color: #7398C7;
}


.deleteButton {
  color: #7398C7;
}