.maincontainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(550px, 1fr));
    gap: 20px;
    padding: 20px;
}

.Graphcard {
    border: 1px solid #d3d3d3;
    height: 55vh;
}

.Graphcard_header {
    background-color: #F2F2F2;
    padding: 10px 10px 10px 20px !important;
}

.Graphcard_content_bar {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.Graphcard_content_doughnut {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center
}

.Graphcard_content_invalid {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}