.barchart-container{
    width:100%;
    min-height: 250px;
    display: flex;
    justify-content: center;
    margin: 0px 16px;
    align-items: center;
}
/* @media (min-width: 2560px) {
    .barchart-container{
        width:300px;
        height: 180px;
    }
  
  }
  @media (min-width:1700px) and (max-width:2000px){
    .barchart-container{
        width:280px;
        height: 180px;
    }
  
  }
  @media (min-width:1300px) and (max-width:1700px){
    .barchart-container{
        width:100%;
        height: 250px;
        margin: 16px;
    }
  
  } */