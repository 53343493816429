.actionsHeader {
  padding-top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: rgba(255, 255, 255, 0.95);
}

.searchContainer {
  padding: 1rem;
  /* border-bottom: 0.125rem dotted #eceef6; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  position: sticky;
  top: 0;
  z-index: 2;
  width: 88%;
}

.searchWrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: #ffffff;
  border: 1px solid #dedede;
  border-radius: 8px;
  padding: 0.3rem 1.25rem;
  width: 21rem;
  box-sizing: border-box;
}

.searchInput {
  width: 100%;
  border: none;
  outline: none;
  font-size: 0.875rem;
  color: #a7a7a7;
  background: transparent;
}

.searchInput::placeholder {
  color: #a7a7a7;
  opacity: 1;
}

.searchIcon {
  color: #a7a7a7;
  margin-right: 0.5rem;
}


.resultCount {
  position: absolute;
  right: 5%;
  top: 100%;
  transform: translateY(-50%);
  font-size: 0.75rem;
  color: #64748b;
}

.bulkActions {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.selectAllLabel {
  cursor: pointer;
  user-select: none;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 4px;
  border-radius: 6px;
  transition: all 0.2s ease;
}

.checkbox:hover {
  background: rgba(0, 0, 0, 0.05);
}

.checkIcon {
  color: #6B6B6B;
}

.actions {
  display: flex;
  gap: 0.5rem;
  padding: .8rem;
  opacity: 0.5;
  transition: opacity 0.3s ease;
  align-items: center;
  justify-content: space-between;
}

.actions.active {
  opacity: 1;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 8px 12px;
  border: none;
  border-radius: 6px;
  background: #F2F2F2;
  color: #4F63BE;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
  height: 2rem;
}

.actionButtonContainer {
  display: flex;
  gap: 1rem;
  align-items: center;
}

.actionButton:hover:not(:disabled) {
  background: #e2e8f0;
}

.actionButton:disabled {
  cursor: not-allowed;
  opacity: 0.8;
}

.actionButton.deleteButton {
  background: #ffffff;
  /* color: #ef4444; */
}

.actionButton.deleteButton:hover:not(:disabled) {
  background: #ffffff;
}