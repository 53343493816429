@property --theme-primary { syntax: '<color>'; inherits: true; initial-value: #4F63BE; }
@property --theme-secondary { syntax: '<color>'; inherits: true; initial-value: #A7ACE9; }

.react-datepicker {
  background: linear-gradient(180deg, #FFF 0%, #F8F9FF 100%);
  border: 1px solid rgba(167, 172, 233, 0.3);
  box-shadow: 0 10px 30px -5px rgba(79, 99, 190, 0.1);
  border-radius: 16px;
  padding: 16px;
  width: min(100%, 320px);
  backdrop-filter: blur(8px);
}

.react-datepicker-wrapper { width: 100%; }

.react-datepicker__header {
  background: transparent;
  border: none;
  padding: 0 0 12px;
  position: relative;
}
.react-datepicker__day--in-range,
.react-datepicker__day--range-start{
  background-color: rgb(186.25, 217.0833333333, 241.25) !important;
}
.react-datepicker__header::after {
  content: '';
  position: absolute;
  bottom: 0;
  inset-inline: -16px;
  height: 1px;
  background: linear-gradient(90deg, transparent 0%, rgba(167, 172, 233, 0.3) 15%, rgba(167, 172, 233, 0.3) 85%, transparent 100%);
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-time__caption {
  color: var(--theme-primary);
  font-size: 1rem;
  font-weight: 600;
}

.react-datepicker__month { margin: 0.2rem -4px; padding: 4px; }
.react-datepicker__month-container { float: none; }

.react-datepicker__day-names {
  margin-top: 8px;
  background: linear-gradient(180deg, transparent 0%, rgba(167, 172, 233, 0.1) 100%);
  border-radius: 8px;
  padding: 4px 0;
}

.react-datepicker__day, 
.react-datepicker__day-name,
.react-datepicker__time-name {
  color: var(--theme-primary);
  width: 32px;
  height: 32px;
  line-height: 32px;
  margin: 2px;
  border-radius: 8px;
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

.react-datepicker__day--outside-month { color: rgba(167, 172, 233, 0.6); }
.react-datepicker__day--disabled { color: #A7ACE9; opacity: 0.5; cursor: not-allowed; }

.react-datepicker__day:hover,
.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__time-list-item:hover { 
  background: rgba(167, 172, 233, 0.2);
  color: var(--theme-primary);
}

.react-datepicker__day--selected,
.react-datepicker__time-list-item--selected {
  background: linear-gradient(45deg, var(--theme-primary), var(--theme-secondary)) !important;
  color: white !important;
  font-weight: 500;
  box-shadow: 0 4px 12px rgba(79, 99, 190, 0.2);
}

.react-datepicker__today-button {
  background: linear-gradient(45deg, var(--theme-primary), var(--theme-secondary));
  color: white;
  font-size: 0.875rem;
  font-weight: 500;
  padding: 8px;
  border: none;
  cursor: pointer;
  transition: 0.2s ease;
  margin: 8px -16px -16px;
  box-shadow: 0 4px 12px rgba(79, 99, 190, 0.2);
}

.react-datepicker__today-button:hover {
  background: linear-gradient(45deg, #424F99, var(--theme-primary));
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown {
  background: white;
  border: 1px solid rgba(167, 172, 233, 0.3);
  border-radius: 12px;
  box-shadow: 0 10px 15px -3px rgba(79, 99, 190, 0.1);
  padding: 8px 0;
  width: 160px;
  max-height: 280px;
  overflow-y: auto;
}

.react-datepicker__close-icon::after {
  background-color: transparent;
  color: red;
  font-size: 1rem;
}
.react-datepicker__header__dropdown--select {
  display: flex;
    justify-content: space-between;
    align-items: center;
}

.react-datepicker__year-dropdown-container--select, .react-datepicker__month-dropdown-container--select, .react-datepicker__month-year-dropdown-container--select, .react-datepicker__year-dropdown-container--scroll, .react-datepicker__month-dropdown-container--scroll, .react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0;
}

.react-datepicker__day--keyboard-selected {
  background-color: transparent;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-select,
.react-datepicker__year-select {
  padding: .2rem;
  width: 5rem;
  color: var(--theme-primary);
  background: white;
  border: 1px solid rgba(167, 172, 233, 0.3);
  border-radius: 8px;
  cursor: pointer;
  transition: 0.2s ease;
}

.react-datepicker__input-time-container {
  margin: 2rem 0 8px;
  padding: 0 12px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
}

.react-datepicker-time__input-container { width: 100%; }

.react-datepicker-time__input {
  background: white;
  border: 1px solid rgba(167, 172, 233, 0.3);
  border-radius: 8px;
  padding: .2rem;
  color: var(--theme-primary);
  display: flex;
  width: 90%;
  justify-content: center;
}

.react-datepicker-time__input > input {
  width: 96%;
  border: none;
  outline: none;
}



@media (max-width: 480px) {
  .react-datepicker {
    position: fixed;
    inset: 50% auto auto 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    z-index: 1000;
  }
}

