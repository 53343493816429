.configuration {
  /* background-color: white; */
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 2rem;
}

.list {
  padding-left: 0;
  display: flex;
  gap: 1rem;
  flex-direction: column;
}

.tabSwitch {
  display: flex;
}

.tabSwitch>span {
  font-size: 0.875rem;
  text-align: center;
  width: 100%;
  color: #949494;
}

.tabButton {
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  padding-bottom: 6.5px;
  border: none;
  line-height: 19.36px;
  background-color: #F5F9FF;
  color: #000000;
  cursor: pointer;
  margin-right: 30px;
  border-bottom: 2px solid transparent;
  font-family: 'Inter';
}

.activeTab {
  color: #4f63be;
  border-bottom: 2px solid #4f63be;
  font-weight: 700;
  border-radius: 0;
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.gridContainer {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(clamp(250px, 30vw, 450px), 1fr));
  grid-column-gap: 5rem;
  grid-row-gap: 14px;
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 20px;

}

.inputGridItem {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
}

.inputGridItem>label {
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #333;
  white-space: nowrap;
}