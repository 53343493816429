.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /* background: white; */
  box-sizing: border-box;
}

.headerSection {
  /* background: rgb(252, 253, 255); */
  padding: 2rem;
  /* box-shadow: 0 4px 20px rgba(147, 152, 199, 1); */
  position: sticky;
  top: 0;
  z-index: 10;
}

.headerContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0 auto;
  box-sizing: border-box;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border: none;
  border-radius: 1.5rem;
  background: #4F63BE;
  color: #FFF;
  cursor: pointer;
  transition: all 0.2s ease;
}

/* .headerIcon {
  color: #4F63BE;
  width: 1.5rem;
  height: 1.5rem;
} */

.headerTitle {
  font-weight: 600;
  color: #1C1C1C;
  font-size: 16px;
  margin: 0;
}

.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 0rem 2rem;
  /* padding-top: 2.5rem; */
  gap: 1rem;
  overflow-y: auto;
  /* background: rgb(252, 253, 255); */
  box-sizing: border-box;
}

.formContainer {
  display: flex;
  gap: 1rem;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.warning {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0.75rem 1rem;
  background-color: #fef8f0;
  border: 1px solid #ffecd1;
  border-radius: 0.375rem;
  color: #b95000;
  font-size: 0.875rem;
  margin: 1rem;
}

.warningIcon {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

.infoBox {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: .5rem;
  background: rgba(167, 172, 233, 0.05);
  border-radius: 0.5rem;
  border: 1px solid rgba(167, 172, 233, 0.1);
}

.infoIcon {
  color: #4F63BE;
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
}

.infoText {
  margin: 0;
  font-size: 0.875rem;
  color: #4F63BE;
  line-height: 1.5;
}

@media (max-width: 768px) {
  .headerSection {
    padding: 0.75rem;
  }

  .mainContent {
    padding: 0.75rem;
  }

  .warning {
    margin: 0.75rem;
  }

  .infoBox {
    padding: 0.75rem;
    margin-bottom: 1rem;
  }
}