.listItem {
  list-style-type: none;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.addButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0 0 0 auto;
  background: #4F63BE;
  border: none;
  color: white;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.addButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  pointer-events: none;
}

.leftWrap {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  margin-bottom: .5rem;
}

.leftWrap>span {
  font-weight: 500;
  font-size: 1rem;
  line-height: 19.36px;
  color: black;
}