.container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  position: relative;
  box-sizing: border-box;
}

.label {
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
}

.label.disabled {
  opacity: 0.5;
}

.required {
  color: #DC2626;
  margin-left: 0.25rem;
}

.dropdown {
  position: relative;
  width: 100%;
  height: 100%;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 0.3rem .8rem;
  background: #ffffff;
  border: 0.0625rem solid #e5e7eb;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}

.header.headerbackground {
  background: #F0F7FF;
  border: none
}

.header:hover:not(.disabled) {
  border-color: #4F63BE;
}

.header.open {
  border-color: #3b82f6;
  box-shadow: 0 0 0 0.125rem rgba(59, 130, 246, 0.1);
}

.header.disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: #f3f4f6;
}

.header.invalid {
  border-color: #DC2626;
}

.header.invalid:hover {
  border-color: #DC2626;
}

.header.invalid.open {
  border-color: #DC2626;
  box-shadow: 0 0 0 0.125rem rgba(220, 38, 38, 0.1);
}

.headerContent {
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  margin-right: 0.75rem;
}

.selectedText {
  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.875rem;
  color: #1f2937;
  line-height: 1.5;
  text-align: left;
}

/* New styles for placeholder */
.selectedText.placeholder {
  color: #9ca3af;
  font-style: italic;
}

.icon {
  width: 1rem;
  height: 1rem;
  flex-shrink: 0;
  color: #6b7280;
}

.list {
  position: absolute;
  top: calc(100% + 0.25rem);
  left: 0;
  right: 0;
  background: #ffffff;
  border: 0.0625rem solid #e5e7eb;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1.25rem rgba(0, 0, 0, 0.15);
  max-height: 18.75rem;
  overflow-y: auto;
  z-index: 1000;
}

.list::-webkit-scrollbar {
  width: 0.375rem;
}

.list::-webkit-scrollbar-track {
  background: transparent;
  margin: 0.25rem;
}

.list::-webkit-scrollbar-thumb {
  background: #d1d5db;
  border-radius: 0.1875rem;
}

.list::-webkit-scrollbar-thumb:hover {
  background: #9ca3af;
}

.search {
  padding: 0.5rem;
  border-bottom: 0.0625rem solid #e5e7eb;
  position: sticky;
  top: 0;
  background: #ffffff;
  z-index: 2;
  border-radius: 0.5rem 0.5rem 0 0;
}

.searchInput {
  width: 100%;
  padding: 0.5rem;
  border: 0.0625rem solid #e5e7eb;
  border-radius: 0.375rem;
  font-size: 0.875rem;
  outline: none;
  transition: all 0.2s ease;
  box-sizing: border-box;
}

.searchInput:focus {
  border-color: #3b82f6;
  box-shadow: 0 0 0 0.125rem rgba(59, 130, 246, 0.1);
}

.searchInput::placeholder {
  color: #9ca3af;
  font-style: italic;
}

.noResults {
  padding: 1rem;
  text-align: center;
  color: #6b7280;
  font-size: 0.875rem;
}

.validationMessage {
  display: flex;
  align-items: flex-start;
  gap: 0.25rem;
  margin-top: 0.25rem;
}

.validationIcon {
  flex-shrink: 0;
  margin-top: 0.125rem;
}

.validationText {
  margin: 0;
  padding: 0;
  flex: 1;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: normal;
}

.errorMessage {
  color: #DC2626;
}

.warningMessage {
  color: #F59E0B;
}