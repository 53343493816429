.gridItem {
    display: flex;
    align-items: flex-start;
    gap: 24px;
    justify-content: space-between;
}

.textField {
    width: 75%;
}

.textField .MuiInputBase-root {
    height: 75%;
}

.textField .MuiInputBase-input {
    height: 75%;
    padding: 10px;
}