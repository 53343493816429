.pageContainer {
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
}

.inboxContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
  margin-top: 4.5rem;
}

.mainContent {
  display: flex;
  flex: 1;
  /* justify-content: center; */
  overflow: hidden;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #ff4444;
  font-size: 1rem;
  padding: 1rem;
  text-align: center;
}

/* Responsive styles */
@media screen and (max-width: 768px) {
  .inboxContainer {
    margin-left: 0.4rem;
    margin-right: 0.4rem;
  }

  .mainContent {
    flex-direction: column;
  }
}

/* Optional: Add transitions */
.mainContent {
  transition: all 0.3s ease;
}

/* Optional: Add loading state */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #666666;
  font-size: 1rem;
}

/* Optional: Improve scrollbar styling */
.mainContent::-webkit-scrollbar {
  width: 8px;
}

.mainContent::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.mainContent::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.mainContent::-webkit-scrollbar-thumb:hover {
  background: #555;
}