.dateRangePickerInput {
    border: 1px solid #dedede;
    border-radius: 8px;
    padding: 0.5rem;
    font-size: 10px;
    font-weight: 600;
    color: #6b6b6b;
    width: 100%;
    /* max-width: 261px; */
    text-align: left;
    outline: none;
    line-height: 100%;
    height: 32px;
    letter-spacing: 1px;
    box-sizing: border-box;
    font-family: inter;
  }
  
  .dateInputWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .calendarIcon {
    position: absolute;
    left: 10px;
    cursor: pointer;
    color: #6b6b6b;
    height: 12px;
    z-index: 1;
  }
  
  .dateRangePickerInput {
    padding-left: 35px; /* Space for the icon */
    width: 100%;
  }
  
