
@media (max-width:1700px) and (min-width:1200px) {
    .customer-card-charts{
        flex-direction: column !important;
    }
    .customer-card-doughnutchart{
        max-width: 100% !important;
    }
    .customer-card-barchart{
        max-width: 100% !important;
    
    }
}