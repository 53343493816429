.notificationsListContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  background: white;
  overflow: auto;
}

.notificationsList {
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
}

/* .notificationWrapper {
  transition: transform 0.2s ease;
  box-shadow: 0 4px 6px -4px #96baef;
} */

/* .notificationWrapper:hover {
  transform: translateX(4px);
} */

/* Loading State */
.loadingState {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  color: #6B7280;
  padding: 2rem;
}

.loadingIcon {
  animation: spin 1s linear infinite;
}

/* Empty State */
.emptyState {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: #6B7280;
  text-align: center;
  gap: 1rem;
}

.emptyIcon {
  color: #E5E7EB;
  margin-bottom: 0.5rem;
}

/* Pagination */
.paginationContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  border-top: 1px solid #F3F4F6;
  background: white;
}

.paginationButtons {
  display: flex;
  gap: 0.5rem;
}

.paginationButton {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: 1px solid #E5E7EB;
  background: white;
  color: #374151;
  font-size: 0.875rem;
  cursor: pointer;
  transition: all 0.2s ease;
}

.paginationButton:hover {
  background: #F3F4F6;
  border-color: #6B7280;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}