.activitiesContainer {
    margin-top: 10px;
  }
  
  .imageCard {
    border: 0px solid #d3d3d3;
    border-radius: 24px!important;
    min-height: 60vh;
    /* max-height: 78vh; */
    
  }
  
  .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    
    /* border-radius: 24px; Ensure it's a box */
  }
  
  .cardImage {
    max-width: 150%;
    max-height: 150%;
    object-fit: cover;
  }
  .viewButton{
    background-color: #4F63BE;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 35px; /* Adjusts spacing around button */
    width:88px;
    height:43px;
    text-align: center;
    font-family: Inter, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
  }
  .viewButton_integration{
    background-color: #4F63BE;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 35px; /* Adjusts spacing around button */
    width:88px;
    height:43px;
    text-align: center;
    font-family: Inter, Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
  }
  .viewButton_workflow {
    background-color: #7398C7;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 10px; /* Adjusts spacing around button */
    width:88px;
    height:43px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.36px;
    letter-spacing: 1%;
    font-family: Inter, Arial, sans-serif;
  }

  /* .viewButton_integration {
    padding: 8px 16px;
    background-color: #4F63BE;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  }
  
  .viewButton_workflow {
    padding: 8px 16px;
    background-color: #7398C7;
    color: white;
    border: none;
    border-radius: 12px;
    cursor: pointer;
  } */
  
  .viewButton:hover {
    background-color: #3e51a0;
  }
  
  .cardText {
    text-align: left;
    color: #555555;
  }
  